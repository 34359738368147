import ConsultingSlider from './modules/consultingSlider';
import Testimonials from './modules/testimonials';
import ProjectLogos from './modules/projectLogos';
import Youtube from './modules/youtube';
import ImagesSlider from './modules/imagesSlider';
import MobileNav from './modules/mobileNav';
import Newsletter from './modules/newsletter';
import TeaserLinks from './modules/teaserLinks';
import Fancybox from './modules/fancybox';
import Stepper from './modules/stepper';
import Box from './modules/box';
import SFCard from './modules/sfcard';
import ContactForm from './modules/contactForm';
import WebinarsSlider from './modules/webinarsSlider';


// Initialize mobile Navigation
new MobileNav();

// Stepper component
const steppers = document.querySelectorAll(".stepper");;
if (steppers.length > 0) {
  new Stepper(steppers);
};

// Newsletter Modal and trigger in the footer
if (document.getElementById('newsletter')) {
  new Newsletter();
};

// Handle YouTube ifremes
const ytVideos = document.querySelectorAll('.video--youtube');
ytVideos.forEach(yt => {
  new Youtube(yt);
});

// Initialize services slider on homepage
const consultingSwiper = document.querySelector('.consulting__swiper');
if (consultingSwiper)
  new ConsultingSlider(consultingSwiper);

// Initialize webinars slider on homepage
const webinarsSlider = document.querySelector('.home-webinars__swiper');
if (webinarsSlider) new WebinarsSlider(webinarsSlider);

// Regular image sliders
const imageSliders = document.querySelectorAll('.images--slider');
imageSliders.forEach(is => {
  new ImagesSlider(is);
});

// Initialize testimonals slider globaly
const testimonialsSliders = document.querySelectorAll('.testimonials__slider');
testimonialsSliders.forEach(ts => {
  new Testimonials(ts);
});

// Initialize Boxes
const boxes = document.querySelectorAll('.box');
boxes.forEach(box => {
  new Box(box);
});

// Initialize Smart Factory Cards
const sfcards = document.querySelectorAll('.sf-card');
sfcards.forEach(sfcard => {
  new SFCard(sfcard);
});

// Initialize Images gallery
const fancybox = document.querySelectorAll('.fancybox__gallery');
fancybox.forEach(fb => {
  new Fancybox(fb);
});

// Initialize Teaser Link toggle
const teaserLinks = document.querySelectorAll('.teaserlinks .teaserlinks__more');
teaserLinks.forEach(tl => {
  new TeaserLinks(tl);
});

// Initialize project logo slider
const projectLogosSwiper = document.querySelector('.project-logos__swiper');
if (projectLogosSwiper) {
  new ProjectLogos(projectLogosSwiper);
};

// Initialize contact form
const contactForm = document.getElementById('contactForm');
if (contactForm) {
  new ContactForm(contactForm);
}