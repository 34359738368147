export default class webinarsSlider {

  constructor(elem) {
    this.swiper = null;
    this.elem = elem;
    this.swiper = null;
    this.init();
  }

  init() {
    console.log("lets go webinars slider");

    let swiperOptions = {
      speed: 600,
      loop: false,
      autoplay: 6000,
      roundLengths: true,
      grabCursor: true,
      height: 'auto',
      pagination: {
        el: document.querySelector('.home-webinars .swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: document.querySelector('.home-webinars .swiper-button-next'),
        prevEl: document.querySelector('.home-webinars .swiper-button-prev'),
      },
      slidesPerView: 3,
      spaceBetween: 16,
      breakpoints: {
        2500: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1600: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        1200: {
          slidesPerView: 2.1,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        576: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        320: {
          slidesPerView: 1.1,
          spaceBetween: 16,
        }
      }
    };

    new Swiper(this.elem, swiperOptions);
  }
}
